import React from "react";
import styled from "styled-components";
import { navigate } from "gatsby";
import { Text } from "basis";

import {
  Button,
  Column,
} from "../../../components";
import { COLOR } from "../../../components/theme";
import {
  MERCHANT_PAGE_ROUTES
} from "../../../core/constants";
import { MobileHeading } from ".";
import { getLinkedProfiles, setItem } from "../../../core/auth/utils/storage";
import fetchLinkedProfiles from "../../../core/auth/profile/fetchLinkedProfiles";
import { CURRENT_USER, MODE } from "../../../core/auth/constants";
const StyledColumn = styled(Column)`
  border-bottom: 1px dashed ${COLOR.LIGHT_GREY};
  padding: 0 10px;

  p {
    word-break: break-all;
  }

  &:hover {
    cursor: pointer;
    background-color: ${COLOR.LIGHT_SKY_BLUE};
  }
`;

const StyledOrderDetail = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 12px 8px;

  &.icon {
    border: none;
    justify-content: center;
    height: 18px;
    margin-bottom: 25px;

    a span {
      color: ${COLOR.HIGHLIGHT_BLUE};
      border-bottom-color: ${COLOR.HIGHLIGHT_BLUE};
      font-weight: 400;
    }
  }
`;

// TODO: needs refactoring after old dashboard is deleted
const MerchantRow = ({
  merchantDetails,
  profile,
  merchantId,
  changeProfile
}) => {
  return (
    <>
      <StyledColumn
        template="1fr"
        templateTablet="2fr 2fr 2fr"
      >


        <StyledOrderDetail>
          <MobileHeading title="Merchant" tablet />
          <Text align="left" textStyle="body2" color="grey.t75">
            {merchantDetails}
          </Text>
        </StyledOrderDetail>

        <StyledOrderDetail>
          <MobileHeading title="MerchantId" tablet />
          <Text align="left" textStyle="body2" color="grey.t75">
            {merchantId}
          </Text>
        </StyledOrderDetail>
        <StyledOrderDetail>
          <MobileHeading title="Action" tablet />
          {merchantId === profile?.merchantId && <Button
            variant="primary"
            showButton={true}
            onClick={() => { }}
          >
            Selected
          </Button>}
          {merchantId !== profile?.merchantId && <Button
            variant="secondary"
            showButton={true}
            onClick={async() => {
              const currentProfile = await fetchLinkedProfiles(
                1,
                '',
                merchantId,
              ); 
              const currentProfiles= await getLinkedProfiles(); 
              const result = currentProfiles.filter((item)=> item.merchantId===merchantId)
              if(result.length === 0){
               let currentMatchedProfile= currentProfile.profiles
               const updatedProfiles = [...currentProfiles, ...currentMatchedProfile]
              setItem(CURRENT_USER, { mode: MODE.MERCHANT, profiles: updatedProfiles });
              }
              navigate(MERCHANT_PAGE_ROUTES.Default);
              changeProfile(merchantId);
            }}
          >
            Select
          </Button>
          }
        </StyledOrderDetail>
      </StyledColumn>

    </>
  );
};

export default MerchantRow;
